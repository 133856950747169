<div class="sdds-div-navbar">
  <nav class="sdds-navbar">
    <div class="sdds-navbar-overlay "></div>
    <div class="sdds-navbar-application-brand cursor-pointer" [routerLink]="['/content/specifications']">
      DCODE
    </div>

    <ul class="sdds-navbar-menu-list">
      <li class="sdds-navbar-menu-item-dropdown" [ngClass]="{'opened':contentMenu}" (click)="changeContentMenuState()">
        <a class="sdds-navbar-menu-item-link"> Content <span class="sdds-icon-arrow"></span> </a>
        <ul class="sdds-navbar-menu__dropdown-menu">
          <li class="sdds-navbar-menu__dropdown-item" *ngIf="currentSpecificationVersion">
            <a [routerLink]="['/specification', currentSpecification.id, currentSpecificationVersion.id]">Content</a>
          </li>
          <li class="m-5 p-5 border-b-2" *ngIf="!currentSpecificationVersion"
            [routerLink]="['/content/specifications']">
            Diagnostic Specifications
          </li>
          <li class="m-5 p-5 border-b-2" *ngIf="currentSpecificationVersion"
            [routerLink]="['/specification', currentSpecification.id, currentSpecificationVersion.id]">
            Diagnostic Specifications
          </li>
          <li class="m-5 p-5 border-b-2" [routerLink]="['/content/diagnostic-families']">
            Diagnostic Families
          </li>
          <li class="m-5 p-5 border-b-2" [routerLink]="['/content/identification-groups']">
            Identification Groups
          </li>
          <li class="m-5 p-5" [routerLink]="['/content/shared-files']">
            Shared Files
          </li>
        </ul>
      </li>

      <li class="sdds-navbar-menu-item-dropdown" [ngClass]="{'opened':exportMenu}" (click)="changeExportMenuState()">
        <a class="sdds-navbar-menu-item-link"> Export <span class="sdds-icon-arrow"></span> </a>
        <ul class="sdds-navbar-menu__dropdown-menu">
          <li class="m-5 p-5 border-b-2" [routerLink]="['/release/view']">Packages</li>
          <!-- <li class="m-5 p-5" [routerLink]="['/release/export']">Label</li> -->
          <li class="m-5 p-5" (click)="onLabelClick()">Label</li>
          <li class="m-5 p-5" (click)="onSpecificationLabelClick()">Specifications Label</li>
        </ul>
      </li>

      <!-- <li class="sdds-navbar-menu-item-dropdown" [routerLink]="['/rbac-files']"> -->
      <li class="sdds-navbar-menu-item-dropdown" (click)="onRbaccClick()">
        <a class="sdds-navbar-menu-item-link"> RBACC Files </a>
      </li>
    </ul>

    <div class="sdds-navbar-menu-toolbar-mobile">
      <ul class="sdds-navbar-menu-list">
        <li *ngIf="username" class="sdds-navbar-menu-item-dropdown" [ngClass]="{'opened':nameMenu}"
          (click)="changeNameMenuState()">
          <a class="sdds-navbar-menu-item-link" title="To change the role please contact Dcode helpdesk">
            <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>&nbsp;
            {{userRole}} - {{username}}
          </a>
          <ul class="sdds-navbar-menu__dropdown-menu">
            <li class="m-5 p-5" [routerLink]="['/content/admin-data-table']">
              User Details
            </li>
            <li class="m-5 p-5" [routerLink]="['/content/manage-templates']" *ngIf="canManageTemplate()">
              Manage Templates
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="sdds-navbar-scania-brand"></div>
  </nav>
</div>
