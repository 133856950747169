<div *ngIf="!isReadOnly" id="expressionIcon" style="float:right;">
  <button class="btn btn-link btn-xs toolbar-btn" *ngIf="reorderEnabled" style="cursor: pointer; text-decoration: none;"
    [disabled]="!canEditCategoryItems()" (click)="identificationExpressionItemService.onMoveUp.next(expressionModel)">
    <mat-icon svgIcon="arrow-up"></mat-icon>
  </button>
  <button class="btn btn-link btn-xs toolbar-btn" *ngIf="reorderEnabled" style="cursor: pointer; text-decoration: none;"
    [disabled]="!canEditCategoryItems()" (click)="identificationExpressionItemService.onMoveDown.next(expressionModel)">
    <mat-icon svgIcon="arrow-down"></mat-icon>
  </button>
  <button class="btn btn-link btn-xs toolbar-btn" *ngIf="copyEnabled" style="cursor: pointer; text-decoration: none;"
    [disabled]="!canEditCategoryItems()" (click)="identificationExpressionItemService.onCopy.next(expressionModel)">
    <mat-icon svgIcon="documents"></mat-icon>
  </button>
  <button class="btn btn-link btn-xs toolbar-btn" *ngIf="removeEnabled" style="cursor: pointer; text-decoration: none;"
    [disabled]="!canEditCategoryItems()" (click)="openDialog(expressionModel)">
    <mat-icon svgIcon="x-close"></mat-icon>
  </button>
</div>
